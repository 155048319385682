import {ITerraceTypes} from "./Terrace";
import styles from "./styles/_BlockTitleCS.module.scss";
import ClickableLink from "../../utilities/ClickableLink";
import {withoutLocaleInURI} from "../../../internationalization/i18nURI";
import ImageComponent from "../../utilities/ImageComponent";
import FontAwesome from "../../utilities/FontAwesome";
import {observer} from "mobx-react";
import URI from 'urijs';
import classNames from "classnames";

const ViewMoreButton = observer((props: {
    terraceType?: ITerraceTypes,
    organizationId?: string,
    text?: string,
    className?: string,
    url?: string,
}) => {
    const {text = "View More"} = props;

    const params = {};
    if (props.terraceType) {
        params["type"] = props.terraceType;
    }
    if (props.organizationId) {
        params["orgId"] = props.organizationId;
    }

    const url = props.url ? props.url : new URI(withoutLocaleInURI("/feed")).setSearch(params).toString();

    const viewMoreButtonClassName = classNames({
        [styles.viewMoreButtonContainer]: true,
        [props.className || ""]: props.className,
    })

    return <div className={viewMoreButtonClassName}>
        <ClickableLink
            href={url}>
            <span>{text}</span>
            <div>
                <ImageComponent src={require("../../../assets/images/school-feed-ashley-version.png")}
                                aria-hidden={true}/>
                <FontAwesome name={'fa-chevron-right'} prefix={'fas'} ariaHidden={true}/>
            </div>
        </ClickableLink>
    </div>
})

export default ViewMoreButton;
